'use client';

import { FlagsmithClientProvider } from '@openfeature/flagsmith-client-provider';
import { OpenFeature, OpenFeatureProvider } from '@openfeature/react-sdk';
import flagsmith from 'flagsmith/isomorphic';
import type { IState } from 'flagsmith/types';
import type React from 'react';

type Props = {
  state: IState;
  children: React.ReactNode;
};

const OpenFeatureClientProvider = ({ state, children }: Props) => {
  const flagsmithClientProvider = new FlagsmithClientProvider({
    api: state.api,
    flagsmithInstance: flagsmith,
    state,
  });
  OpenFeature.setProvider(flagsmithClientProvider); // Attach the provider to OpenFeature

  return <OpenFeatureProvider>{children}</OpenFeatureProvider>;
};

export default OpenFeatureClientProvider;
