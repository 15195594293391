'use client';

import { useEffect } from 'react';

interface JiraCollectorProps {
  collecctionLink: string;
}

// 🔹 Extend the window object to define ATL_JQ_PAGE_PROPS
declare global {
  interface Window {
    ATL_JQ_PAGE_PROPS?: {
      triggerFunction: (showCollectorDialog: () => void) => void;
      fieldValues?: Record<string, string>;
    };
  }
}

const JiraCollector = ({ collecctionLink }: JiraCollectorProps) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = collecctionLink;
    script.async = true;
    script.defer = true;

    script.onload = () => {
      console.log('Jira Issue Collector script loaded');
      if (typeof window.ATL_JQ_PAGE_PROPS !== 'undefined') {
        window.ATL_JQ_PAGE_PROPS = {
          triggerFunction(showCollectorDialog: () => void) {
            showCollectorDialog();
          },
        };
      }
      console.log('ATL_JQ_PAGE_PROPS set:', window.ATL_JQ_PAGE_PROPS);
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // No UI, just loads the script
};

export default JiraCollector;
