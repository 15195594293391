import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/Providers/ClientProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/app/Providers/OpenFeatureClientProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/JiraCollector/JiraCollector.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/SessionWrapper.tsx");
;
import(/* webpackMode: "eager" */ "/app/src/styles/global.css");
